import React from "react";
import "./Footer.css";
import nova from "../../../assets/images/dmnova.png";
import logo from "../../../assets/images/khotoua.png";
import facebook from "../../../assets/images/FACEBOOK3.png";
import linkedin from "../../../assets/images/LINKEDIN3.png";
import insta from "../../../assets/images/INSTAGRAM3.png";
import youtube from "../../../assets/images/youtube.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    window.scrollTo({
      behavior: "smooth",
      top: section.offsetTop,
    });
  };
  return (
    <div>
      <nav className="footer1">
        <button className="social">
          <a href="https://www.youtube.com/@Khotoua">
            <img
              href="#"
              src={youtube}
              alt="Logo"
              className="footer-logo"
            />
          </a>
          <a href="https://www.linkedin.com/company/khotoua/">
            <img src={linkedin} alt="Logo" className="footer-logo" />
          </a>
          <a href="https://www.instagram.com/khotoua_game/">
            <img href="#" src={insta} alt="Logo" className="footer-logo" />
          </a>
          <a href="https://www.facebook.com/khotoua.game">
            <img href="#" src={facebook} alt="Logo" className="footer-logo" />
          </a>
        </button>

        <ul className="footer-items">
          <Link onClick={() => scrollToSection("best")} className="footer-item">
            {" "}
            مميزات التطبيق ◀︎
          </Link>
          <Link
            onClick={() => scrollToSection("trust")}
            className="footer-item"
          >
            {" "}
            وثقوا بنا ◀
          </Link>
          <Link
            onClick={() => scrollToSection("contact")}
            className="footer-item"
          >
            {" "}
            تواصل معنا ◀︎
          </Link>
        </ul>

        <ul className="footer-items">
          <Link onClick={() => scrollToSection("home")} className="footer-item">
            {" "}
            ... لكل إنجاز خطوة{" "}
          </Link>
          <Link onClick={() => scrollToSection("home")} className="footer-item">
            من نحن ؟ ◀︎
          </Link>
          <Link
            onClick={() => scrollToSection("contenu")}
            className="footer-item"
          >
            المحتويات التعليمية ◀︎
          </Link>
          <Link to={"khotoua.html"} className="footer-item">
            السياسة العامة◀︎
          </Link>
        </ul>

        <div className="khot">
          <Link to="/" onClick={() => scrollToSection("home")}>
            <img src={logo} alt="khotoua" className="navbarr-logo" />
          </Link>
        </div>
      </nav>
      <a href="https://dmnova.tech/">
        <div className="footer2">
          <img src={nova} className="nova" />: المنتج المنفذ
        </div>
      </a>
    </div>
  );
};

export default Footer;
