import React from 'react';
import './TrustBoxMobile.css';
import StarRatingComponent from 'react-star-rating-component';


const TrustBoxMobile = ({ name, profession, msg, rate, imagelink }) => {
    return (
        <div className='Box_Mo'>

            <div className='info_Mo'>

                <div className='txte_Mo'>

                    <div className='txt_Mo'>
                        <div className='image_Mo'>
                            <img src={imagelink} className='userImage'></img>
                        </div>

                        <div className='prof_Mo'>{profession}</div>
                    </div>


                    <div className='img_rate'>
                        <div className='rate_Mo'>
                            <StarRatingComponent
                                name="rate2"
                                editing={false}
                                starCount={5}
                                value={rate}
                                className='stars_Mo'
                            />
                        </div>
                        <div className='name_Mo'>{name}</div>

                    </div>

                </div>

                <div className='msg_Mo'>{msg}</div>

            </div>







        </div>
    );
};

export default TrustBoxMobile;

