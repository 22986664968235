import React from "react";
import "./TrustBox.css";
import StarRatingComponent from "react-star-rating-component";

const TrustBox = ({ name, profession, msg, rate, imagelink }) => {
  return (
    <div className="Box">
      <div className="info">
        <div className="txte">
          <div className="rate">
            <StarRatingComponent
              name="rate2"
              editing={false}
              starCount={5}
              value={rate}
              className="stars"
            />
          </div>
          <div className="image">
            <img className="UserImage" src={imagelink}></img>
          </div>
        </div>

        <div className="txt">
          <div className="name">{name}</div>
          <div className="prof">{profession}</div>
          <div className="msg">{msg}</div>
        </div>
      </div>
    </div>
  );
};

export default TrustBox;
