import "./App.css";
import Policies from "./components/common/Policies/Policies";

import Home from "./components/pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
     
    </Routes>
  );
}

export default App;
