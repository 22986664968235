import React from 'react';
import frame from '../../../assets/images/Frame.png';
import frame2 from '../../../assets/images/Frame2.png';
import pyramide from '../../../assets/images/pyramide.png';
import parents from '../../../assets/images/parents.png';
import real from '../../../assets/images/real.png';
import Fade from 'react-reveal/Fade';
import './Best.css';


const Best = () => {
    return (
        <div id='best'>

            <Fade bottom duration={1700}>
                <h1 className='mmapp'>مميزات التطبيق</h1>
            </Fade>

            <div className='all'>
                <Fade bottom duration={1700}>
                    <div className='sec1'>
                        <Fade right duration={1700}>
                            <img src={frame} className='frame' />
                        </Fade>

                        <Fade bottom duration={1700}>
                            <div className='pa'>

                                <h1 className='ttapp'>محتوى متوافق مع المناهج الدراسيّة</h1>
                                <text className="parag" dir="rtl" >
                                    <p> في تطبيق "خُطوة"، نحرص على تطابق جميع الموادّ الدراسيّة المُقدّمة مع البرامج</p>
                                    <p>الرسميّة للمرحلة الابتدائية وحين تتعدّاها فمن أجل إسنادها وإغنائها والتوسّع في مضامينها.</p>
                                    <p>فريقنا البيداغوجي المحترف والتدقيق المتعدّد المستويات ضمانتا الإعداد الجيّد للأنشطة التعليميّة والألعاب التربويّة التي تستند إلى المناهج والمقاربات الرسميّة</p>
                                </text>
                            </div>

                        </Fade>

                    </div>



                    <div className='sec2'>

                        <div className='pa'>

                            <h1 className='ttapp'>   تجاوز الصعوبات </h1>
                            <p className="parag" >

                                <p> ،تطبيق "خُطوة" يتميّز بالقدرة على الكشف على صعوبات التعلّم لدى بعض الأطفال </p>
                                <p>  ممّا يُمكّن الآباء والمعلّمين من تقديم الدعم المناسب والملائم للأطفال عبر أنشطة خضعت لتكييفات خصوصيّة</p>
                                <p> نحن نعمل على تطوير تطبيق "خُطوة" ليكون رفيقًا موثوقًا للأطفال في رحلة تعليميّة فريدة ومُمتعة، حيث يمكن للأطفال توظيف أقصى إمكاناتهم و تجاوز صعوباتهم التعليميّة وتحقيق أفضل النتائج</p>
                            </p>
                        </div>

                        <img src={frame2} className='frame2' />


                    </div>
                </Fade>

                <div className='sec5'>
                    <Fade right duration={1700}>
                        <img src={real} className='real' />
                    </Fade>
                    <Fade bottom duration={1700}>
                        <div className='pa'>

                            <h1 className='ttapp'>الواقع المُعزّز</h1>
                            <text className="parag">
                                <p> من خلال الواقع المُعزّز، يستمتع الأطفال بتفاعل ثلاثيّ الأبعاد مع الكائنات والموادّ</p>
                                <p> والشّخص يات الافتراضيّة المُرتبطة بالموضوعات التّعليميّة، ممّا يُعزّز تجربتهم ويعمّق</p>
                                <p>.معرفتهم بالمحتويات الدراسية وتدرجهم بالمراقي العرفانية</p>

                            </text>
                        </div>

                    </Fade>

                </div>


                <Fade bottom duration={1700}>

                    <div className='sec3'>

                        <div className='pa'>

                            <h1 className='ttapp'> هرم<span className='coll' > بلوم </span> للأهداف التعليمية</h1>
                            <p dir="rtl" className="parag">
                                <p>بنيت المحتويات و القدرات المستهدفة بتدرج يستند إلى هرم "بلوم" إذ:</p>
                                <p> 1. يستكشف المتعلّم المعرفة ويخزّنها بذاكرته ويستحضرها. </p>
                                <p>2. يمارس أنشطة تضمن فهْم تلك المعرفة فهما مُكتملا.</p>
                                <p> 3. يطبّق المكتسب ضمن وضعيات معيشة ويوظّفها توظيفا متدرّجا.</p>
                                <p> 4. يحلّل المعارف والمهارات المكتسبة ويعيد تركيبها لاستدماجها ضمن منظومته.</p>
                                <p>  5. يقيّم المكتسب، يسند له قيمة ويطلق عليه حكما موضوعيّا.</p>
                                <p>  6. يبتكر ويبدع منتجا يستند إلى المكتسب محتوى وتمشّيات.</p>
                            </p>
                        </div>
                        <img src={pyramide} className='pyramide' />
                    </div>

                    <div className='sec4'>
                        <img src={parents} className='parents' />
                        <div className='pa'>

                            <h1 className='ttapp'>ما يقدمه تطبيق خطوة للوالدين </h1>
                            <p className="parag">

                                <p> ،في تطبيق خطوة الأمّ والأب والوليّ عموما كلٌّ مرافق حقيقيّ لمنظوره</p>
                                <p> يطّلع على مُنجزه ودرجات تقدّمه في بناء تجربته التعليميّة، تتّضح له</p>
                                <p> الصعوبات وفق تغذية راجعة لا تنقطع وتتوفّر له أنشطة دعم خصوصيّة</p>
                                <p>.تستجيب للحاجات وتساعد على تذليل الصعوبات</p>
                                <p> كلّ ذلك في إطار من الموارد المساعدة على معرفة البرنامج الدراسيّ</p>
                                <p> .والأهداف المأمولة والقدرات المستهدفة ومؤشرات النجاح</p>
                                <p>.وهي معلومات محيّنة متجدّدة وحيّة</p>
                                <p>!في تطبيق خُطوة أُرافقُ طفلي خُطوة خُطوة</p>

                            </p>
                        </div>

                    </div>
                </Fade>








            </div>


        </div>


    );
};

export default Best;
