import React from "react";
import phone from "../../../assets/images/phone.png";
import Fade from "react-reveal/Fade";
import appel from "../../../assets/images/appel.png";
import web from "../../../assets/images/web.png";
import store from "../../../assets/images/store.png";
import huawei from "../../../assets/images/huawei.png";
import "./Download.css";

const Download = () => {
  return (
    <div className="download" id="download">
      <Fade left duration={2000}>
        <img src={phone} className="phone" alt="khotoua-khotwa-خطوة.png" />
      </Fade>

      <div className="alll-para">
        <Fade right duration={2000}>
          <h1 className="stepp">: حمل التطبيق الآن </h1>
        </Fade>
        <Fade right duration={1000} delay={500}>
          <p className="paraa">
            <p> وفر لطفلك الرّفيق المثاليّ لتعزيز</p>
            <p> تجربته التّعليميّة بطريقة ممتعة ومشوّقة</p>
          </p>
        </Fade>

        <Fade bottom delay={1000} duration={700}>
          <div className="icons">
            <a
              href={require("../../../assets/khotoua.apk")}
              download="khotoua.apk"
              target="_blank"
              className="flex flex-col justify-center items-center text-white"
            >
              <img href="#" src={huawei} className="huawei" />
              <p>متجر هواوي</p>
            </a>
            <a
              href="#"
              target="_blank"
              className="flex flex-col justify-center items-center text-white"
            >
              <img href="#" src={appel} className="appel" />
              <p>متجر أبل</p>
            </a>
            <a
              className="flex flex-col justify-center items-center text-white"
              href="https://play.google.com/store/apps/details?id=com.DMNovaComany.Khotoua&hl=en&gl=US"
            >
              <img href="#" src={store} className="store" />
              <p>متجر جوجل</p>
            </a>
            <a
              className="flex flex-col justify-center items-center text-white"
              href="#"
            >
              <img href="#" src={web} className="web" />
              <p>نسخة الويب</p>
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Download;
