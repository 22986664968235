import React from "react";
import avatar from "../../../assets/images/khotoua-avatar.png";

import Fade from "react-reveal/Fade";
import "../About/About.css";
function Policies() {
  return (
    <div className="">
      <div>
        <div
          className="about"
          id="about"
          style={{ flex: 1, flexDirection: "row", alignItems: "start" }}
        >
          <img src={avatar} className="khotoua-avatar" />

          <Fade right>
            <div className="all-para">
              <h1 id="policies" className="titerr">
                السياسة العامة
              </h1>

              <div
                className="rectangles"
                style={{ direction: "rtl", flex: 1, flexDirection: "column" }}
              >
                <p>
                  <strong>سياسة الخصوصية لتطبيق خطوة</strong>
                </p>
                <p>
                  نشكركم على استخدام تطبيق خطوة. تهدف هذه السياسة إلى توضيح
                  كيفية جمعنا واستخدامنا ومشاركتنا للمعلومات الشخصية التي نحصل
                  عليها منكم أثناء استخدامكم لتطبيقنا. من فضلك قم بقراءة السياسة
                  بعناية لفهم كيفية حماية خصوصيتك وبياناتك الشخصية.
                </p>
                <p>
                  <strong>1. جمع المعلومات:</strong>
                </p>
                <ul>
                  <li>
                    نقوم بجمع بعض المعلومات الشخصية التي تقدمونها لنا عند
                    التسجيل أو استخدام التطبيق، مثل الاسم وعنوان البريد
                    الإلكتروني ورقم الهاتف.
                  </li>
                  <li>
                    قد نقوم بجمع بيانات تقنية تلقائياً عند استخدامكم للتطبيق،
                    مثل عنوان IP ونوع المتصفح ونظام التشغيل.
                  </li>
                </ul>
                <p>
                  <strong>2. استخدام المعلومات:</strong>
                </p>
                <ul>
                  <li>
                    نستخدم المعلومات التي نجمعها لتقديم وتحسين خدماتنا، وتخصيص
                    تجربتكم، وإرسال إشعارات هامة وتحديثات عن التطبيق.
                  </li>
                  <li>
                    قد نستخدم المعلومات لأغراض تسويقية، بما في ذلك إرسال رسائل
                    ترويجية وعروض خاصة.
                  </li>
                </ul>
                <p>
                  <strong>3. مشاركة المعلومات:</strong>
                </p>
                <ul>
                  <li>
                    قد نشارك المعلومات الشخصية مع شركاء خارجيين مثل مقدمي
                    الخدمات الذين يساعدوننا في تشغيل التطبيق وتقديم الخدمات.
                  </li>
                  <li>
                    نضمن أن يلتزم شركاؤنا بمعايير الخصوصية والأمان المماثلة لتلك
                    الموجودة في سياسة الخصوصية الخاصة بنا.
                  </li>
                </ul>
                <p>
                  <strong>4. حماية البيانات:</strong>
                </p>
                <ul>
                  <li>
                    نحافظ على معايير أمان عالية لحماية بياناتك الشخصية من الوصول
                    غير المصرح به والاستخدام غير القانوني أو التغيير غير المصرح
                    به.
                  </li>
                </ul>
                <p>
                  <strong>5. سياسة الاحتفاظ بالبيانات، إدارة معلوماتك:</strong>
                </p>
                <ul>
                  <li>
                    سنحتفظ بالبيانات المقدمة من المستخدم طالما أنك تستخدم
                    التطبيق ولفترة معقولة بعد ذلك.
                  </li>
                  <li>
                    إذا كنت تريد منا حذف حسابك، بما في ذلك جميع بيانات الحساب
                    بشكل دائم، ارسل لنا طلبك على البريد التالي وسنرد في وقت
                    معقول: delete.account@khotoua.com
                  </li>
                  <li>
                    يرجى ملاحظة أن بعض أو كل البيانات المقدمة من المستخدم قد
                    تكون مطلوبة حتى يعمل التطبيق بشكل صحيح.
                  </li>
                </ul>
                <p>
                  <strong>6. تحديثات السياسة:</strong>
                </p>
                <ul>
                  <li>
                    قد نقوم بتحديث سياسة الخصوصية هذه بشكل دوري، وسنقوم بإشعاركم
                    بأي تغييرات مهمة تؤثر على حقوقكم.
                  </li>
                </ul>
                <p>
                  من خلال استخدامكم لتطبيق خطوة، فإنكم توافقون على جمعنا واست
                </p>
                <p>خدامنا ومشاركتنا لمعلوماتكم وفقًا لهذه السياسة الخصوصية.</p>
                <p>
                  لأية أسئلة أو استفسارات، يرجى التواصل معنا على العنوان التالي:
                  [contact@khotoua.com].
                </p>
                <p>تاريخ آخر تحديث: 01/01/2024.</p>
              </div>
              <div>
                <br />

                <p style={{ fontSize: 28 }}>
                  <strong>
                    Politique de confidentialité de l&#39;application Khotoua
                  </strong>
                </p>
                <br />
                <p>
                  Nous vous remercions d&#39;utiliser l&#39;application Khotoua.
                  Cette politique vise à expliquer comment nous collectons,
                  utilisons et partageons les informations personnelles que nous
                  recueillons auprès de vous lorsque vous utilisez notre
                  application. Veuillez lire attentivement cette politique pour
                  comprendre comment nous protégeons votre vie privée et vos
                  données personnelles.
                </p>
                <br />

                <p>
                  <strong>1. Collecte des informations :</strong>
                </p>
                <ul>
                  <li>
                    Nous recueillons certaines informations personnelles que
                    vous nous fournissez lors de l&#39;inscription ou de
                    l&#39;utilisation de l&#39;application, telles que votre
                    nom, votre adresse e-mail, et votre numéro de téléphone.
                  </li>
                  <li>
                    Nous pouvons également collecter automatiquement des données
                    techniques lorsque vous utilisez l&#39;application, telles
                    que votre adresse IP, le type de navigateur, et le système
                    d&#39;exploitation.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>2. Utilisation des informations :</strong>
                </p>
                <ul>
                  <li>
                    Nous utilisons les informations que nous collectons pour
                    fournir et améliorer nos services, personnaliser votre
                    expérience, et envoyer des notifications importantes et des
                    mises à jour concernant l&#39;application.
                  </li>
                  <li>
                    Nous pouvons également utiliser les informations à des fins
                    marketing, y compris l&#39;envoi de messages promotionnels
                    et d&#39;offres spéciales.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>3. Partage des informations :</strong>
                </p>
                <ul>
                  <li>
                    Nous pouvons partager des informations personnelles avec des
                    partenaires externes tels que les fournisseurs de services
                    qui nous aident à exploiter l&#39;application et à fournir
                    des services.
                  </li>
                  <li>
                    Nous nous assurons que nos partenaires respectent des normes
                    de confidentialité et de sécurité similaires à celles
                    énoncées dans notre politique de confidentialité.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>4. Protection des données :</strong>
                </p>
                <ul>
                  <li>
                    Nous maintenons des normes de sécurité élevées pour protéger
                    vos données personnelles contre tout accès, utilisation ou
                    modification non autorisé.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>
                    5. Politique de conservation des données, gestion de vos
                    informations :
                  </strong>
                </p>
                <ul>
                  <li>
                    Nous conserverons les données fournies par l&#39;utilisateur
                    aussi longtemps que vous utiliserez l&#39;application et
                    pour une période raisonnable par la suite.
                  </li>
                  <li>
                    Si vous souhaitez que nous supprimions votre compte, y
                    compris toutes les données associées, veuillez nous envoyer
                    votre demande à l&#39;adresse e-mail suivante et nous vous
                    répondrons dans les plus brefs délais :
                    delete.account@khotoua.com
                  </li>
                  <li>
                    Veuillez noter que certaines ou toutes les données fournies
                    par l&#39;utilisateur peuvent être nécessaires pour le bon
                    fonctionnement de l&#39;application.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>6. Mises à jour de la politique :</strong>
                </p>
                <ul>
                  <li>
                    Nous pouvons mettre à jour cette politique de
                    confidentialité périodiquement et vous informer de tout
                    changement important qui affecte vos droits.
                  </li>
                  <li>
                    En utilisant l&#39;application Khotoua, vous acceptez la
                    collecte, l&#39;utilisation et le partage de vos
                    informations conformément à cette politique de
                    confidentialité.
                  </li>
                </ul>
                <br />

                <p>
                  Pour toute question ou demande d&#39;information, veuillez
                  nous contacter à l&#39;adresse suivante :
                  [contact@khotoua.com].
                </p>
                <p>Dernière mise à jour : 01/01/2024.</p>
              </div>
              <div>
                <br />

                <p style={{ fontSize: 28 }}>
                  <strong>Privacy Policy for Khotoua App</strong>
                </p>
                <br />

                <p>
                  We appreciate your use of the Khotoua app. This policy aims to
                  explain how we collect, use, and share the personal
                  information we obtain from you when you use our app. Please
                  read this policy carefully to understand how we protect your
                  privacy and personal data.
                </p>
                <br />

                <p>
                  <strong>1. Information Collection:</strong>
                </p>
                <ul>
                  <li>
                    We collect some personal information you provide to us when
                    registering or using the app, such as name, email address,
                    and phone number.
                  </li>
                  <li>
                    We may also automatically collect technical data when you
                    use the app, such as IP address, browser type, and operating
                    system.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>2. Use of Information:</strong>
                </p>
                <ul>
                  <li>
                    We use the information we collect to provide and improve our
                    services, customize your experience, and send important
                    notifications and updates about the app.
                  </li>
                  <li>
                    We may also use the information for marketing purposes,
                    including sending promotional messages and special offers.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>3. Sharing of Information:</strong>
                </p>
                <ul>
                  <li>
                    We may share personal information with external partners
                    such as service providers who assist us in operating the app
                    and providing services.
                  </li>
                  <li>
                    We ensure that our partners adhere to privacy and security
                    standards similar to those outlined in our privacy policy.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>4. Data Protection:</strong>
                </p>
                <ul>
                  <li>
                    We maintain high security standards to protect your personal
                    data from unauthorized access, use, or alteration.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>
                    5. Data Retention Policy, Information Management:
                  </strong>
                </p>
                <ul>
                  <li>
                    We will retain data provided by the user for as long as you
                    use the app and for a reasonable period thereafter.
                  </li>
                  <li>
                    If you wish for us to delete your account, including all
                    associated data, please send us your request to the
                    following email address, and we will respond promptly:
                    delete.account@khotoua.com
                  </li>
                  <li>
                    Please note that some or all of the data provided by the
                    user may be necessary for the proper functioning of the app.
                  </li>
                </ul>
                <br />

                <p>
                  <strong>6. Policy Updates:</strong>
                </p>
                <ul>
                  <li>
                    We may update this privacy policy periodically and inform
                    you of any significant changes that affect your rights.
                  </li>
                  <li>
                    By using the Khotoua app, you agree to the collection, use,
                    and sharing of your information in accordance with this
                    privacy policy.
                  </li>
                </ul>
                <br />

                <p>
                  For any questions or inquiries, please contact us at the
                  following address: [contact@khotoua.com].
                </p>
                <p>Last Updated: 01/01/2024.</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Policies;
