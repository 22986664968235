import React from 'react';
import img1 from '../../../assets/images/img1.png';
import img2 from '../../../assets/images/img2.png';
import img3 from '../../../assets/images/img3.png';
import img4 from '../../../assets/images/img4.png';
import img52 from '../../../assets/images/img52.png';
import img6 from '../../../assets/images/img6.png';
import Fade from 'react-reveal/Fade';
import './Contenu.css';

const Contenu = () => {
    return (
        <div className='respo' id='contenu'>


            <Fade top duration={1500}>
                <nav className='nav'><p className="nav-item"> المحتويات التعليمية</p></nav>
            </Fade>

            <div className='background2'>
                <div className='background3'>

                    <div className='iconss'>


                        <div className='icons1'>
                            <Fade left duration={1500}>
                                <div className='text3'>
                                    <img src={img6} />
                                    <p >مخبر العلوم</p>
                                </div>
                            </Fade>

                            <Fade bottom delay={1000}>
                                <div className='text2' >
                                    <img src={img52} className='img5' />
                                    <p >الواقع المعزز</p>
                                </div>
                            </Fade>

                            <Fade right duration={1500}>
                                <div className='text1'>
                                    <img src={img4} />
                                    <p >ألعاب التعليمية</p>
                                </div>
                            </Fade>


                        </div>



                        <div className='icons2'>
                            <Fade left duration={1500}>
                                <div className='text6'>
                                    <img src={img3} />
                                    <p>فيديوهات وقصص مفيدة</p>
                                </div>

                            </Fade>
                            <Fade bottom delay={1000}>
                                <div className='text5'>
                                    <img src={img2} />
                                    <p>فنون المحادثة  </p>
                                </div>
                            </Fade>

                            <Fade right duration={1500}>
                                <div className='text4'>
                                    <img src={img1} />
                                    <p> مهاراتي الحياتيّة </p>
                                </div>

                            </Fade>




                        </div>

                    </div>


                </div>

            </div>





        </div>

    );
};

export default Contenu;

//je veux chaque image a un titre on bottom
<div className='texts'>
    <div className='text1'>
        <p>علوم</p>
        <p>الواقع المعزز</p>
        <p>ألعاب التعليمية</p>
    </div>

    <div className='text2'>
        <p>فيديوهات وقصص مفيدة</p>
        <p>محتوى تعليمي تفاعلي</p>
        <p>فنون التلوين</p>
    </div>
</div>
