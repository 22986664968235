import React, { useState } from "react";
import "./Trust.css";
import TrustBox from "../../layout/TrustBox/TrustBox";
import imgbox from "../../../assets/images/imgbox.png";
import TrustBoxMobile from "../../layout/TrustBoxMobile/TrustBoxMobile";
import habiba from "../../../assets/images/habiba.png";
import salma from "../../../assets/images/salma.jpg";
import najm from "../../../assets/images/najm.png";
import med from "../../../assets/images/med.jpg";
import aya from "../../../assets/images/aya.jpg";

const Trust = () => {
  const [activeLink, setActiveLink] = useState("msgR");

  const handleClick = (link) => {
    setActiveLink(link);
  };

  const [feedbackUser, setFeedbackUser] = useState([
    {
      name: "عائشة",
      profession: "متفقدة عامة للمدارس الابتدائية لغة فرنسية",
      rate: 1.5,
      msg: "  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليلوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود ايت,سيت دو أيوسمود ي",
      imagelink: imgbox,
    },
    {
      name: "عائشة",
      profession: "متفقدة عامة للمدارس الابتدائية لغة فرنسية",
      rate: 5,
      msg: "  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليلوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود ايت,سيت دو أيوسمود ي",
      imagelink: imgbox,
    },

    {
      name: "عائشة",
      profession: "متفقدة عامة للمدارس الابتدائية لغة فرنسية",
      rate: 5,
      msg: "  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليلوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود ايت,سيت دو أيوسمود ي",
      imagelink: imgbox,
    },
    {
      name: "عائشة",
      profession: "متفقدة عامة للمدارس الابتدائية لغة فرنسية",
      rate: 5,
      msg: "  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليلوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود ايت,سيت دو أيوسمود ي",
      imagelink: imgbox,
    },
    {
      name: "عائشة",
      profession: "متفقدة عامة للمدارس الابتدائية لغة فرنسية",
      rate: 5,
      msg: "  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليلوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود ايت,سيت دو أيوسمود ي",
      imagelink: imgbox,
    },
  ]);
  const [feedbackParent, setFeedbackParent] = useState([
    {
      name: "محمد زيراوي",
      profession: "",
      rate: 5,
      msg: " هي بمثابة كبسولات تربوية تعليمية  حديثة ومبتكرة متطابقة مع البرامج الرسمية و تمس الذكاءات المميزة لكل متعلم وأنماط تعلمه.فهي تعالج الصعوبات و تنمي المهارات والقدرات للتلميذ مع سهولة في الاستعمال",
      imagelink: med,
    },
    {
      name: "آية النّوي",
      profession: "أستاذ المدارس الابتدائية",
      rate: 5,
      msg: "  المتعة والتعلم  في رحلة فريدة لاستكشاف العالم بطريقة مشوقة ومسلية.  هذا هو تطبيق خطوة، المبتكر  التونسي الذي يجمع بين الألعاب والتعلم في آن واحد... لتعزيز المفاهيم التعليمية مع تقييم فردي وتقارير تطويرية هنيئا لكم",
      imagelink: aya,
    },
    {
      name: "نجم الدين جرفال",
      profession: "أستاذ مدارس ابتدائيّة فوق الرّتبة مميّز  متقاعد",
      rate: 5,
      msg: "هي خطوة  ثابتة  للارتقاء بالمتعلم نحو التميّز  وبناء معارفه على أسس صلبة والسموّ به  نحو الأفضل  والتعلم في جو من الاستمتاع  اعتمادا على ما يقدّم له من  تمارين  تهدف إلى تجويد  مكتسباته  بطريقة  رائعة تقوده إلى التفوّق.",
      imagelink: najm,
    },
    {
      name: "سالمة الحبيبي",
      profession: "أستاذ أول فوق الرتبة للمدارس الابتدائية",
      rate: 5,
      msg: "لكل  بداية خطوة أولى. و تطبيق خطوة سيكون الخطوة الأولى نحو التميّز .",
      imagelink: salma,
    },
    {
      name: "حبيبة الرايس",
      profession: "مدرّسة العربيّة للمبتدئين.",
      rate: 5,
      msg: "في زمن التكنولوجيا خطوتان ضروريتان، خطوة للمعرفة وخطوة للتكنولوجيا وذاك تطبيق خطوة.",
      imagelink: habiba,
    },
  ]);
  return (
    <div id="trust">
      <div className="fix">
        <h1 className="titer"> وثقوا بنا </h1>

        <nav className="nav2">
          {/* <h1
            className={`nav-item1 ${activeLink === "msgL" ? "active" : ""}`}
            onClick={() => handleClick("msgL")}
          >
            الاباء والأمّهات
          </h1> */}
          <h1
            className={`nav-item1 ${activeLink === "msgR" ? "active" : ""}`}
            onClick={() => handleClick("msgR")}
          >
            المعلّمون والمعلّمات
          </h1>
        </nav>
      </div>

      <div className="backgroundff">
        <div className="messages">
          {/* {activeLink === "msgL" && (
            <div className="msgL">
              {feedbackUser.map((user, index) => (
                <TrustBoxMobile
                  key={index}
                  name={user.name}
                  profession={user.profession}
                  rate={user.rate}
                  msg={user.msg}
                  imagelink={user.imagelink}
                />
              ))}
            </div>
          )} */}

          {activeLink === "msgR" && (
            <div className="msgR">
              {feedbackParent.map((user, index) => (
                <TrustBoxMobile
                  key={index}
                  name={user.name}
                  profession={user.profession}
                  rate={user.rate}
                  msg={user.msg}
                  imagelink={"./man.png"}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Trust;
