import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/khotoua.png";
import parents from "../../../assets/images/parent.png";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { FaBars, FaTimes } from "react-icons/fa";

import "./Navbar.css";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    window.scrollTo({
      behavior: "smooth",
      top: section.offsetTop,
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Fade top>
      <nav className="navbar">
        <div className="navbar-left">
          <Link to="/home/parent" className="button-parent">
            <img src={parents} alt="parent" className="button-parentt" />
          </Link>
        </div>

        <div
          className={`mobile-menu-icon ${isMobile ? "active" : ""}`}
          onClick={toggleMobileMenu}
        >
          {isMobile ? <FaBars /> : <FaTimes />}
        </div>

        <ul className={`navbar-items  ${isMobile ? "mobile-open" : ""}`}>
          <li>
            <span
              
              className="navbar-item text-white"
              onClick={() => scrollToSection("download")}
            >
              حمل التطبيق
            </span>
          </li>

          <li>
            <span
             
              className="navbar-item text-white"
              onClick={() => scrollToSection("contact")}
            >
              تواصل معنا
            </span>
          </li>

          <li>
            <span
            
              className="navbar-item text-white"
              onClick={() => scrollToSection("trust")}
            >
              يثق بنا الكثير
            </span>
          </li>
          <li>
            <span
              to="best"
              className="navbar-item text-white"
              onClick={() => scrollToSection("best")}
            >
              مميزات التطبيق
            </span>
          </li>
          <li className="navbar-item33">
            <span
              to="contenu"
              className="navbar-item text-white"
              onClick={() => scrollToSection("contenu")}
            >
              المحتويات التعليمية
            </span>
          </li>
          <li>
            <Link to="/" className="navbar-item-parent">
              فضاء الولي
            </Link>
          </li>
        </ul>

        <div className="navbar-right">
          <Link to="/" onClick={() => scrollToSection("home")}>
            <img src={logo} alt="khotoua-khotwa-خطوة.png" className="navbar-logo" />
          </Link>
        </div>
      </nav>
    </Fade>
  );
};

export default Navbar;
