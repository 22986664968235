import React from "react";
import facebook from "../../../assets/images/FACEBOOK3.png";
import linkedin from "../../../assets/images/LINKEDIN3.png";
import youtube from "../../../assets/images/youtube.svg";
import insta from "../../../assets/images/INSTAGRAM3.png";
import iconclient from "../../../assets/images/iconclient.png";
import iconmail from "../../../assets/images/iconmail.png";
import iconsocial from "../../../assets/images/iconsocial.png";

import "./Contact.css";

const Contact = () => {
  return (
    <div id="contact" className="nna">
      <h1 className="titerr">تواصل معنا</h1>

      <div className="rectangles">
        <div className="rec3">
          <img href="#" src={iconsocial} alt="Logo" className="box1-icon" />
          <h3 className="p1"> مواقع التواصل </h3>
          <div className="socials">
            <a href="https://www.youtube.com/@Khotoua">
              <img href="#" src={youtube} alt="Logo" className="contact-logo" />
            </a>
            <a href="https://www.linkedin.com/company/khotoua/">
              <img src={linkedin} alt="Logo" className="contact-logo" />
            </a>
            <a href="https://www.instagram.com/khotoua_game/">
              <img href="#" src={insta} alt="Logo" className="contact-logo" />
            </a>
            <a href="https://www.facebook.com/khotoua.game">
              <img
                href="#"
                src={facebook}
                alt="Logo"
                className="contact-logo"
              />
            </a>
          </div>
        </div>

        <div className="rec2">
          <img href="#" src={iconmail} alt="Logo" className="box1-icon" />
          <h3 className="p1"> البريد الالكترونى الاجتماعي </h3>
          <p className="numbeer">contact@khotoua.com</p>
        </div>

        <div className="rec1">
          <img href="#" src={iconclient} alt="Logo" className="box1-icon" />
          <h3 className="p1"> خدمة الحرفاء </h3>
          <p className="number">+216 56 078 078</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
