import React, { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbar';
import Footer from '../../layout/Footer/Footer';
import down_icon from '../../../assets/images/Down_icon.png';
import flech from '../../../assets/images/flech.png';
import { useLocation } from 'react-router-dom';
import './Home.css';
import About from '../../common/About/About';
import Contenu from '../../common/Contenu/Contenu';
import Best from '../../common/Best/Best';
import Trust from '../../common/Trust/Trust';
import Contact from '../../common/Contact/Contact';
import Download from '../../common/Download/Download';

import Fade from 'react-reveal/Fade';
import TrustWindows from '../../common/TrustWindows/TrustWindows';
import FooterMobile from '../../layout/FooterMobile/FooterMobile';

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showDownButton, setShowDownButton] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > window.innerHeight);
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div id="home">
      
        {showBackToTop && (
          <button className='Up' onClick={handleScrollToTop}>
            <img src={flech} className='flech' />
          </button>
        )}
      
      <div className='background'>
        <div className='topHome'>
          <Navbar />
          <About />
        </div>
        <Fade bottom>
          <div className='green'></div>
        </Fade>
      </div>

      <Contenu />

      <div className='backgroundOO'>
        <Best />
        {isMobile ? <Trust /> : <TrustWindows />}
        <Contact />
        <div id="download-section">
          <Download />
        </div>
        {isMobile ? <FooterMobile /> : <Footer />}
      </div>
    </div>
  );
};

export default Home;
