import React from "react";
import avatar from "../../../assets/images/khotoua-avatar.png";
import appel from "../../../assets/images/appel.png";
import web from "../../../assets/images/web.png";
import store from "../../../assets/images/store.png";
import huawei from "../../../assets/images/huawei.png";
import Fade from "react-reveal/Fade";
import "./About.css";

const About = () => {
  return (
    <div className="about " id="about">
      <img
        src={avatar}
        className="khotoua-avatar avatar "
        alt="khotoua-khotwa-خطوة.png"
      />

      <Fade right>
        <div className="all-para">
          <h1 className="step"> ... لكل إنجاز خطوة</h1>
          <h2 className="we">من نحن ؟</h2>
          <p className="para">
            <div className="about_desc">
              <p>
                تطبيق خُطوة هو الرّفيق المثاليّ لأطفال المرحلة الابتدائيّة
                الّذين{" "}
              </p>
              <p> .يرغبون في تعزيز تجربتهم التّعليميّة بطريقة ممتعة ومشوّقة</p>
              <p>
                {" "}
                تطبيق أُعدّ من قبل خبراء بيداغوجيين في ضوء البرامج المدرسيّة
              </p>
              <p> .وأفضل الطّرق والمداخل التربويّة المعاصرة</p>
            </div>

            <h2 className="jouer"> : العب الآن </h2>
            <div className="icons">
              <a
                href={require("../../../assets/khotoua.apk")}
                download="khotoua.apk"
                target="_blank"
                className="flex flex-col justify-center items-center text-white"
              >
                <img href="#" src={huawei} className="huawei" />
                <p>متجر هواوي</p>
              </a>
              <a
                href="#"
                target="_blank"
                className="flex flex-col justify-center items-center text-white"
              >
                <img href="#" src={appel} className="appel" />
                <p>متجر أبل</p>
              </a>
              <a
                className="flex flex-col justify-center items-center text-white"
                href="https://play.google.com/store/apps/details?id=com.DMNovaComany.Khotoua&hl=en&gl=US"
              >
                <img href="#" src={store} className="store" />
                <p>متجر جوجل</p>
              </a>
              <a
                className="flex flex-col justify-center items-center text-white"
                href="#"
              >
                <img href="#" src={web} className="web" />
                <p>نسخة الويب</p>
              </a>
            </div>
          </p>
        </div>
      </Fade>
    </div>
  );
};

export default About;
